/* ---------------
  Fonts
--------------- */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;900&display=swap');

:root {
  /* Fonts */
  --raleway: 'Rubik', sans-serif;
  /* Light */
  --bg-black-900: #000000;
  --bg-black-100: #dddddd;
  --bg-black-50: #eff0f4;
  --bg-black-20: #dddddd;
  --bg-opacity: rgba(31, 23, 23, 0.5);
  --text-black-900: #000000;
  --text-black-700: #555555;
  --text-black-600: #666666;
  --text-black-300: #bbbbbb;
  --outer-shadow: 0.4rem 0.4rem 0.4rem #d0d0d0, -0.4rem -0.4rem 0.4rem #f8f8f8;
  --outer-shadow-0: 0 0 0 #d0d0d0, 0 0 0 #f8f8f8;
  --inner-shadow: inset 0.4rem 0.4rem 0.4rem #d0d0d0,
    inset -0.4rem -0.4rem 0.4rem #f8f8f8;
  --inner-shadow-0: inset 0 0 0 #d0d0d0, inset 0 0 0 #f8f8f8;
}

/* override custom css variables for dark theme */
body.dark {
  --bg-black-900: #ffffff;
  --bg-black-100: #212e36;
  --bg-black-50: #212e36;
  --bg-black-20: #cfcfcf93;
  --bg-opacity: rgba(43, 44, 47, 0.5);
  --text-black-900: #ffffff;
  --text-black-700: #ffffff;
  --text-black-600: #bbbbbb;
  --outer-shadow: 0.4rem 0.4rem 0.4rem #222327, -0.4rem -0.4rem 0.4rem #363636;
  --outer-shadow-0: 0 0 0 #222327, 0 0 0 #363636;
  --inner-shadow: inset 0.4rem 0.4rem 0.4rem #222327,
    inset -0.4rem -0.4rem 0.4rem #363636;
  --inner-shadow-0: inset 0 0 0 #222327, inset 0 0 0 #363636;
}

/* ---------------
  General Style
--------------- */
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
}

*:not(i) {
  font-family: var(--raleway);
  font-weight: 400;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: var(--bg-black-50);
}

body.stop-scrolling {
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

/* Utilities */
.container {
  max-width: 120rem;
  width: 90%;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.content-between {
  justify-content: space-between;
}

.content-around {
  justify-content: space-around;
}

.content-evenly {
  justify-content: space-evenly;
}

.content-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.outer-shadow {
  box-shadow: var(--outer-shadow);
}

.inner-shadow {
  box-shadow: var(--inner-shadow);
}

.hover-in-shadow {
  position: relative;
  z-index: 1;
}

.hover-in-shadow:hover {
  box-shadow: var(--outer-shadow-0);
}

.hover-in-shadow:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
}

.hover-in-shadow:hover:after {
  box-shadow: var(--inner-shadow);
}

.btn-1 {
  padding: 1rem 2.5rem;
  font-size: 1.6rem;
  font-weight: 50rem;
  color: var(--skin-color);
  background-color: transparent;
  line-height: 1.5;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3rem;
  transition: all 0.3s ease;
  display: inline-block;
  border: none;
}

.btn-1:after {
  border-radius: 3rem;
}

.effect-wrap .effect {
  position: absolute;
  z-index: -1;
}

.effect-1 {
  width: 4rem;
  height: 4rem;
  /* border: 0.4rem solid #8a48ff; */
  border: 0.5rem solid var(--skin-color);
  left: 55%;
  top: 20%;
  animation: spin 15s linear infinite;
}
.effect-2 {
  left: 10%;
  bottom: 10%;
  width: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation: topBounce 3s ease-in-out infinite;
}
.effect-2 div {
  height: 0.4rem;
  width: 0.4rem;
  background-color: var(--skin-color);
  margin: 0 0.3rem 0.8rem;
}

.effect-3 {
  height: 18rem;
  width: 18rem;
  border: 2.5rem solid var(--skin-color);
  border-radius: 50%;
  left: 40%;
  top: -6rem;
  animation: leftBounce 6s ease-in-out infinite;
}

.effect-4 {
  border-top: 3rem solid transparent;
  border-left: 3rem solid var(--skin-color);
  left: 35%;
  bottom: 25%;
  animation: spin 12s linear infinite;
}

.effect-4:before {
  content: '';
  border-top: 3rem solid transparent;
  border-left: 3rem solid var(--skin-color);
  position: absolute;
  opacity: 0.5;
  left: -3.5rem;
  top: -2.5rem;
}

.effect-5 {
  height: 5rem;
  width: 5rem;
  left: 40%;
  bottom: 25%;
  /* right: 10%; */
  /* top: 30%; */
  display: flex;
  justify-content: space-between;
  border-radius: 50%;
  overflow: hidden;
  animation: spin 15s linear infinite;
}

.effect-5 div {
  width: 0.2rem;
  background-color: var(--skin-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes topBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2.5rem);
  }
}

@keyframes leftBounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(2.5rem);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-2.5rem);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-title {
  padding: 0 1.5rem;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 4rem;
}

.section-title h2 {
  display: inline-block;
  font-size: 3rem;
  font-weight: 700;
  color: var(--text-black-900);
  text-transform: uppercase;
  margin: 0;
}

.section-title h2:before {
  content: attr(data-heading);
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--skin-color);
}

/* .section.hide {
  display: none;
} */
