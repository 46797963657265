.home-section {
  position: relative;
  top: 35px;
}

.home-section .full-screen {
  padding: 5rem 0;
  min-height: 100vh;
}

.home-section .home-text {
  flex: 0 0 60%;
  max-width: 60%;
}

.home-section .home-img {
  flex: 0 0 40%;
  max-width: 40%;
}

.home-section .home-text p {
  color: var(--text-black-300);
  font-size: 2.2rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
}

.home-section .home-text h2 {
  font-size: 5rem;
  font-weight: 700;
  color: var(--text-black-900);
  margin: 0;
}

.home-section .home-text span {
  color: var(--skin-color);
  font-weight: 700;
}
.home-section .home-text h1 {
  font-size: 4rem;
  font-weight: 500;
  color: var(--text-black-600);
  margin: 0;
  text-transform: capitalize;
}

.home-section .home-text .btn-1 {
  margin-top: 3rem;
}

.home-section .home-img .img-box {
  /* max-width: 50rem; */
  display: block;
  margin: auto;
  /* border-radius: 50%; */
  /* padding: 1.5rem; */
}

.home-section .home-img .img-box img {
  width: 100%;
  /* border-radius: 50%; */
  /* border: 1rem solid transparent; */
}
