.about-section {
  padding: 8rem 0 0;
  min-height: 100vh;
}

.about-section .about-img {
  flex: 0 0 40%;
  max-width: 40%;
}

.about-section .about-img .img-box {
  padding: 1.5rem;
  border: 0.15rem solid transparent;
  border-radius: 2.5rem;
  text-align: center;
}

.about-section .about-info {
  padding: 2rem 5rem;
  flex: 0 0 60%;
  max-width: 60%;
}

.about-section .about-info p {
  font-size: 1.7rem;
  letter-spacing: 1px;
  line-height: 2.7rem;
  margin-bottom: 1.5rem;
  color: var(--text-black-600);
}

.about-section .about-info span {
  font-weight: 600;
}

.about-section .about-info .btn-1 {
  margin: 3rem 2rem 0 0;
}

.about-section .social-links {
  margin-top: 4rem;
  flex: 0 0 100%;
  text-align: center;
}

.about-section .social-links a {
  display: inline-block;
  height: 5rem;
  width: 5rem;
  font-size: 2rem;
  text-align: center;
  line-height: 5rem;
  color: var(--text-black-600);
  margin: 0 0.4rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin: 0 1rem;
}

.about-section .social-links a i {
  line-height: 5rem;
}

.about-section .social-links a:after {
  border-radius: 50%;
}

.about-section .social-links a:hover {
  color: var(--skin-color);
}

.about-section .about-tabs {
  padding: 6rem 1.5rem 5rem;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.about-section .about-tabs .tab-item {
  display: inline-block;
  margin: 0 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.6rem;
  color: var(--text-black-600);
  font-weight: 500;
  text-transform: capitalize;
}

.about-section .about-tabs .tab-item:hover:not(.active) {
  opacity: 0.6;
}

.about-section .about-tabs .tab-item.active {
  color: var(--skin-color);
}

.about-section .tab-content {
  flex: 0 0 100%;
  max-width: 100%;
  animation: fadeInTop 0.5s ease;
}

.about-section .skills {
  padding: 0 0 4rem;
}

.about-section .skills .skill-item {
  flex: 0 0 calc(25% - 1.5rem);
  max-width: 25%;
  margin: 2rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section .skills .skill-item img {
  width: 9rem;
  height: 9rem;
}

.about-section .skills .skill-item p {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--text-black-600);
  text-transform: capitalize;
  margin: 2.5rem 0 1rem;
  letter-spacing: 1px;
}

.about-section .skills .skill-item .progress {
  height: 2rem;
  border-radius: 1rem;
  position: relative;
}

.about-section .skills .skill-item .progress-bar {
  background-color: var(--text-black-600);
  position: absolute;
  height: 0.6rem;
  left: 0.7rem;
  top: 0.7rem;
  border-radius: 1rem;
}

.about-section .skills .skill-item .progress-bar span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--text-black-600);
  margin-top: -4rem;
}

.about-section .education,
.about-section .experience {
  padding-bottom: 8rem;
}

.about-section .timeline {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}

.about-section .timeline:before {
  content: '';
  width: 0.5rem;
  height: 100%;
  left: 50%;
  top: 0;
  background-color: var(--bg-black-20);
  position: absolute;
}

.about-section .timeline .timeline-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.about-section .timeline .timeline-item:nth-child(odd) {
  padding-right: calc(50% + 5rem);
  text-align: center;
}

.about-section .timeline .timeline-item:nth-child(even) {
  padding-left: calc(50% + 5rem);
  text-align: center;
}

.about-section .timeline .timeline-item-inner {
  padding: 3rem;
  border-radius: 0.5rem;
  position: relative;
}

.about-section .timeline .timeline-item-inner:before {
  content: '';
  position: absolute;
  width: 3.1rem;
  height: 0.1rem;
  background-color: var(--text-black-300);
  /* background-color: var(--bg-black-100); */
  top: 3.7rem;
  z-index: -1;
}

.about-section
  .timeline
  .timeline-item:nth-child(odd)
  .timeline-item-inner:before {
  right: -3.2rem;
}

.about-section
  .timeline
  .timeline-item:nth-child(even)
  .timeline-item-inner:before {
  left: -3rem;
}

.about-section .timeline .timeline-item-inner .icon {
  height: 3.8rem;
  width: 3.8rem;
  background-color: var(--bg-black-50);
  text-align: center;
  border: 0.1rem solid var(--bg-black-100);
  line-height: 4rem;
  border-radius: 50%;
  position: absolute;
  font-size: 1.6rem;
  color: var(--skin-color);
  top: 1.8rem;
}

.about-section .timeline .timeline-item:nth-child(odd) .icon {
  right: -7.2rem;
}

.about-section .timeline .timeline-item:nth-child(even) .icon {
  left: -6.8rem;
}

.about-section .timeline .timeline-item-inner span {
  font-weight: 50rem;
  color: var(--skin-color);
  display: block;
  margin: 0 0 1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.about-section .timeline .timeline-item-inner h3 {
  font-size: 2rem;
  color: var(--text-black-700);
  font-weight: 600;
  margin: 0 0 0.5rem;
  text-transform: capitalize;
}

.about-section .timeline .timeline-item-inner h4 {
  font-size: 1.6rem;
  font-style: italic;
  color: var(--text-black-600);
  margin: 0;
}

.about-section .timeline .timeline-item-inner p {
  font-style: 1.6rem;
  color: var(--text-black-600);
  line-height: 2.6rem;
  margin: 1.5rem 0;
}
