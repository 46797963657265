.nav-menu {
  position: fixed;
  background-color: var(--bg-black-100);
  padding: 0 1.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
}

.fade-out-effect {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-black-50);
  z-index: -1;
  visibility: hidden;
}

.fade-out-effect.active {
  visibility: visible;
  z-index: 1000;
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.nav-menu.open {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  transition: all 0.3s ease-in;
}

.nav-menu .close-nav-menu {
  height: 4rem;
  width: 4rem;
  display: block;
  font-size: 3.5rem;
  line-height: 3.5rem;
  border-radius: 50%;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  text-align: center;
  color: var(--text-black-600);
  transition: all 0.3s ease;
}

.nav-menu .close-nav-menu:after {
  border-radius: 50%;
}

.nav-menu .nav-menu-inner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu ul {
  padding: 1.5rem;
}

.nav-menu ul li {
  display: block;
  margin-bottom: 2rem;
  text-align: center;
}

.nav-menu ul li:last-child {
  margin-bottom: 0;
}

.nav-menu ul li a {
  display: inline-block;
  font-size: 2.8rem;
  font-weight: 60rem;
  color: var(--text-black-600);
  padding: 0.5rem 3rem;
  text-transform: capitalize;
  border-radius: 3rem;
  transition: all 0.3s ease;
}

.nav-menu ul li a.active {
  color: var(--skin-color);
  font-weight: bold;
}
.nav-menu ul li a:after {
  border-radius: 3rem;
}

.nav-menu .copyright-text {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 1.5rem;
  color: var(--text-black-600);
  transform: translateY(50%) rotate(-90deg);
  left: -5rem;
}
