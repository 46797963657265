.footer {
  padding: 0 0 4rem;
}

.footer hr {
  width: 100%;
  padding-bottom: 0.3rem;
  margin-bottom: 4rem;
  border: none;
  background-color: var(--skin-color);
}

.footer .copy {
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
  color: var(--bg-black-900);
}
