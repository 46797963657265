.contact-section {
  padding: 8rem 0 8rem;
}

.contact-section .contact-item {
  flex: 0 0 calc(25% - 2.5rem);
  max-width: 25%;
  border-radius: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
  margin-bottom: 3rem;
}

.contact-section .contact-item:hover {
  transform: scale(1.08);
}

.contact-section .contact-item-inner {
  padding: 30px 15px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
}

.contact-section .contact-item a {
  width: 100%;
  height: 100%;
}
.contact-section .contact-item-inner .contact-icon {
  font-size: 40px;
  color: var(--skin-color);
}

.contact-section .contact-item-inner span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 15px 0 10px;
  text-transform: capitalize;
}

.contact-section .contact-item-inner p {
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 0;
  word-break: break-word;
}

.contact-section .contact-form {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 50px;
}

.contact-section .w-50 {
  padding: 0 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.contact-section .input-group {
  margin: 0 0 25px;
  transition: all 0.3s ease;
  border-radius: 30px;
}

.contact-section .input-group:after {
  border-radius: 30px;
}

.contact-section .input-group .input-control {
  height: 45px;
  display: block;
  width: 100%;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0 15px;
  color: var(--text-black-700);
}

.contact-section .input-group textarea.input-control {
  height: 180px;
  padding-top: 15px;
  resize: none;
}

.contact-section .submit-btn {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  padding: 0 15px;
}
