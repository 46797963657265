.service-section {
  padding: 8rem 0 2rem;
  /* min-height: 100vh; */
}

.service-section .service-item {
  flex: 0 0 calc(33.33% - 1.5rem);
  max-width: 33.33%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
}

.service-section .service-item-inner {
  padding: 5rem 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 100%;
  transition: all 0.2s ease;
}

.service-section .service-item-inner .icon {
  height: 6rem;
  width: 6rem;
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto 3rem;
  position: relative;
  color: var(--text-black-600);
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-section .service-item-inner .icon:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.service-section .service-item-inner:hover .icon {
  box-shadow: var(--inner-shadow-0);
}

.service-section .service-item-inner:hover .icon:after {
  box-shadow: var(--outer-shadow);
}

.service-section .service-item-inner:hover .icon {
  color: var(--skin-color);
}

.service-section .service-item-inner .icon .service-icon {
  font-size: 2.5rem;
  line-height: 6rem;
  transition: all 0.1s ease;
}

.service-section .service-item-inner h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 0 0 1rem;
  text-transform: capitalize;
}
.service-section .service-item-inner p {
  font-size: 1.6rem;
  color: var(--text-black-600);
  margin: 0;
  line-height: 2.6rem;
}
