/* --------
  Header 
-------- */
.header {
  padding: 1rem 0;
  position: fixed;
  background-color: var(--bg-black-50);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.header .logo a {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  font-size: 3.4rem;
  height: 5rem;
  width: 5rem;
  color: var(--skin-color);
  border-radius: 50%;
  border: 0.3rem solid var(--skin-color);
  font-weight: 700;
  text-transform: uppercase;
}

.header .hamburger-btn {
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.header .hamburger-btn:after {
  border-radius: 50%;
}

.header .hamburger-btn span {
  display: block;
  height: 0.3rem;
  width: 2.5rem;
  background-color: var(--bg-black-900);
  position: relative;
}

.header .hamburger-btn span:before,
.header .hamburger-btn span:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--bg-black-900);
  left: 0;
}

.header .hamburger-btn span:before {
  top: -0.8rem;
}

.header .hamburger-btn span:after {
  top: 0.8rem;
}
