@media (max-width: 991px) {
  .portfolio-section .portfolio-item,
  .service-section .service-item {
    flex: 0 0 47%;
    max-width: 47%;
  }

  .home-section .home-text {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .home-section .home-img {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .home-section .home-text h2 {
    font-size: 4.5rem;
  }

  .home-section .home-text h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .contact-section .w-50,
  .portfolio-popup .description,
  .portfolio-section .portfolio-item,
  .service-section .service-item,
  .about-section .about-img,
  .about-section .about-info,
  .home-section .home-text,
  .home-section .home-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact-section .contact-item {
    flex: 0 0 calc(50% - 2rem);
    max-width: 50%;
  }

  .about-section .about-img {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .about-section .skills .skill-item {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .home-section .home-text {
    order: 2;
  }

  .contact-section .submit-btn,
  .home-section .home-text {
    text-align: center;
  }

  .home-section .full-screen {
    padding: 100px 0;
  }

  .home-section .home-img .img-box {
    margin-bottom: 40px;
    max-width: 300px;
  }

  .effect-1 {
    display: none;
  }

  .effect-2 {
    left: 5%;
  }

  .effect-3 {
    height: 15rem;
    width: 15rem;
    left: 3%;
    border: 1.8rem solid var(--skin-color);
    top: -5rem;
  }

  .effect-4 {
    left: 80%;
    top: 48%;
    display: none;
  }

  .effect-5 {
    display: none;
  }

  .about-section .about-img {
    padding-bottom: 30px;
  }
  .about-section .timeline .timeline-item:nth-child(odd) {
    padding-right: 15px;
    text-align: left;
    padding-left: 75px;
  }

  .about-section .timeline .timeline-item:nth-child(even) {
    padding-left: 75px;
  }

  .about-section .timeline:before {
    left: 35px;
  }

  .about-section .timeline .timeline-item:nth-child(odd) .icon {
    right: auto;
    left: -60px;
  }

  .about-section .timeline .timeline-item:nth-child(even) .icon {
    left: -60px;
  }

  .about-section .timeline .timeline-item {
    margin-bottom: 40px;
  }

  .about-section .timeline .timeline-item:last-child {
    margin-bottom: 0;
  }

  .about-section
    .timeline
    .timeline-item:nth-child(odd)
    .timeline-item-inner:before {
    left: -30px;
    right: auto;
  }

  .portfolio-popup .description {
    margin-bottom: 20px;
  }
}

@media (max-width: 574px) {
  .contact-section .contact-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-section .skills .skill-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .about-section .about-info {
    padding: 0;
  }

  .about-section .about-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home-section {
    top: 0;
  }

  .home-section .home-img .img-box {
    /* margin-bottom: 40px; */
    width: 100%;
  }

  .home-section .home-text h2 {
    font-size: 32px;
  }

  .home-section .home-text h1 {
    font-size: 30px;
  }

  .home-section .home-text p {
    font-size: 25px;
  }
}

@media (max-width: 375px) {
  .home-section .home-text h2 {
    font-size: 25px;
  }

  .home-section .home-text h1 {
    font-size: 23px;
  }

  .home-section .home-text p {
    font-size: 20px;
  }
}
