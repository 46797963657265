.portfolio-section {
  padding: 8rem 0 0;
}

.portfolio-section .section-title {
  margin-bottom: 4rem;
}

.portfolio-section .portfolio-filter {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.portfolio-section .filter-item {
  display: inline-block;
  margin: 0 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-black-600);
  text-transform: capitalize;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}

.portfolio-section .filter-item:hover:not(.active) {
  opacity: 0.6;
}

.portfolio-section .filter-item.active {
  color: var(--skin-color);
}

.portfolio-section .portfolio-item {
  flex: 0 0 calc(33% - 1.5rem);
  max-width: 33.33%;
  padding-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
}

.portfolio-section .portfolio-item.hide {
  display: none;
}

.portfolio-section .portfolio-item.show {
  display: block;
  animation: fadeinTop 0.5s ease;
}

.portfolio-section .portfolio-item-inner {
  padding: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
}

.portfolio-section .portfolio-item-img img {
  border-radius: 0.5rem;
  width: 100%;
  transition: all 0.3s ease;
}

.portfolio-section .portfolio-item-inner:hover .portfolio-item-img img {
  transform: translateY(-25px);
}

.portfolio-section .portfolio-item-img {
  position: relative;
}

.portfolio-section .portfolio-item-img .view-project {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: translateY(-5px);
  z-index: -1;
  opacity: 0;
  /* display: none; */
}

.portfolio-section .portfolio-item-inner:hover .view-project {
  transform: translateY(0px);
  opacity: 1;
}

.portfolio-section .portfolio-item-details {
  display: none;
}

.portfolio-section .portfolio-item-inner p {
  font-size: 1.6rem;
  margin: 1rem 0 0;
  color: var(--text-black-600);
  text-transform: capitalize;
}

/* Portfolio Popup */

.portfolio-popup {
  background-color: var(--bg-black-50);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.portfolio-popup.open {
  visibility: visible;
  opacity: 1;
}

.portfolio-popup .pp-loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1005;
  background-color: var(--bg-opacity);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.portfolio-popup .pp-loader.active {
  visibility: visible;
  opacity: 1;
}
.portfolio-popup .pp-loader div {
  height: 40px;
  width: 40px;
  border: 3px solid var(--skin-color);
  border-radius: 50%;
  border-right: 3px solid transparent;
  animation: spin 2s linear infinite;
}

.portfolio-popup .pp-details {
  max-width: 1350px;
  width: 85%;
  margin: auto;
  width: calc(85% + 30px);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.portfolio-popup .pp-details.active {
  opacity: 1;
}

.portfolio-popup .pp-details-inner {
  padding: 30px 15px;
}

.portfolio-popup .pp-title h2 {
  font-size: 28px;
  color: var(--text-black-900);
  text-transform: capitalize;
  font-weight: 600;
  margin: 0 0 5px;
}

.portfolio-popup .pp-title p {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-black-600);
  margin: 0 0 15px;
  border-bottom: 1px solid var(--bg-black-100);
  padding-bottom: 10px;
}

.portfolio-popup .pp-title p span {
  font-weight: 400;
  text-transform: capitalize;
}

.portfolio-popup .pp-project-details h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 0 0 15px;
  text-transform: capitalize;
}

.portfolio-popup .description {
  flex: 0 0 65%;
  max-width: 65%;
  padding-right: 15px;
}

.portfolio-popup .description p {
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 0;
}

.portfolio-popup .info {
  flex: 0 0 35%;
  max-width: 35%;
}

.portfolio-popup .info ul li {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--text-black-600);
  font-size: 16px;
}

.portfolio-popup .info ul li span {
  font-weight: 400;
}

.portfolio-popup .info ul li a {
  color: var(--skin-color);
}

.portfolio-popup .info ul li:last-child {
  margin-bottom: 0;
}

.portfolio-popup .separator {
  height: 1px;
  width: 100%;
  display: block;
  background-color: var(--bg-black-100);
}

.portfolio-popup .pp-img {
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 5px;
}

.portfolio-popup .pp-main {
  min-height: 100vh;
  max-width: 1350px;
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-popup .pp-main-inner {
  padding: 70px 0 50px;
  position: relative;
}

.portfolio-popup .pp-project-details-btn {
  position: absolute;
  left: 0;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  top: 15px;
  cursor: pointer;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease;
}

.portfolio-popup .pp-project-details-btn i {
  margin-left: 4px;
}

.portfolio-popup .pp-project-details-btn:after {
  border-radius: 30px;
}

.portfolio-popup .pp-close {
  position: absolute;
  right: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  top: 15px;
  text-align: center;
  line-height: 35px;
  font-size: 35px;
  color: var(--text-black-600);
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio-popup .pp-close:after {
  border-radius: 50%;
}

.portfolio-popup .pp-counter {
  position: absolute;
  right: 0;
  height: 40px;
  line-height: 40px;
  bottom: 5px;
  font-size: 16px;
  color: var(--text-black-600);
}

.portfolio-popup .pp-prev,
.portfolio-popup .pp-next {
  position: fixed;
  top: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 1010;
  color: var(--text-black-900);
  transition: ease 0.3s ease;
  opacity: 0.55;
}

.portfolio-popup .pp-prev:hover,
.portfolio-popup .pp-next:hover {
  opacity: 1;
}

.portfolio-popup .pp-prev i,
.portfolio-popup .pp-next i {
  line-height: 40px;
}

.portfolio-popup .pp-prev i {
  transform: rotate(180deg);
}

.portfolio-popup .pp-prev {
  left: 15px;
}

.portfolio-popup .pp-next {
  right: 15px;
}
