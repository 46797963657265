.style-switcher {
  position: fixed;
  right: 0;
  top: 7.7rem;
  padding: 1.5rem;
  width: 20rem;
  z-index: 101;
  border-radius: 5px;
  transition: all 0.3s ease;
  transform: translateX(100%);
  background-color: var(--bg-black-50);
}

.style-switcher.open {
  transform: translateX(-1.5rem);
}

.style-switcher .s-icon {
  position: absolute;
  height: 4rem;
  width: 4rem;
  text-align: center;
  font-size: 2rem;
  right: 100%;
  color: var(--text-black-900);
  margin-right: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  background-color: var(--bg-black-50);
}

.style-switcher .s-icon i {
  line-height: 4rem;
}

.style-switcher .s-icon:after {
  border-radius: 50%;
}

.style-switcher .style-switcher-toggler {
  top: 0;
}

.style-switcher .day-night {
  top: 5.5rem;
}

.style-switcher h4 {
  margin: 0 0 1rem;
  color: var(--text-black-700);
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.style-switcher .colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.style-switcher .colors span {
  display: inline-block;
  height: 3rem;
  width: 3rem;

  border-radius: 50%;
  cursor: pointer;
}

.style-switcher .color-1 {
  background-color: #1fc586;
}

.style-switcher .color-2 {
  background-color: #ffb400;
}

.style-switcher .color-3 {
  background-color: #2196f3;
}

.style-switcher .color-4 {
  background-color: #8e60e9;
}

.style-switcher .color-5 {
  background-color: #ec3853;
}
